import React, { useEffect, useState } from 'react'

const ErrorHandler = () => {
  return (
    <>
      <h1>
        <span>Ocorreu um </span>erro
      </h1>
      <hr />
      <p>
        Não conseguimos finalizar seu processo de <br /> cadastro, por favor
        entre em contato
        <br />
        com <a href="mailto:suporte@bepass.com.br">suporte@bepass.com.br</a>
        <br />
        ou tente novamente mais tarde.
      </p>
    </>
  )
}

export default ErrorHandler
