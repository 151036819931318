import React, { useEffect, useState } from 'react'

const SuccessHandler = () => {
  const redirect = window.sessionStorage.getItem('callbackurl')

  window.sessionStorage.removeItem('token')

  if (redirect) {
    setTimeout(() => {
      if(redirect === '#close'){
        window.close();
      }
      window.sessionStorage.removeItem('callbackurl')
      window.location.href = `${redirect}`
    }, 3000)
  }

  return (
    <>
      <h1>
        <span>Tudo </span>pronto!
      </h1>
      <hr />
      <p className="text-center">
        Seu cadastro facial foi realizado com sucesso
        <br />
        {redirect === '#close' ? <><br />Feche essa aba e retorne ao carrinho para concluir sua compra.<br /></> : redirect ? <>Estamos te redirecionando de volta.</>:<></>}
        <br />
        Obrigado!
      </p>
    </>
  )
}

export default SuccessHandler
