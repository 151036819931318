import React, { useEffect, useRef, useState } from 'react'
import { BASE } from '../../basedata/constants'

import './LoadingHandler.sass'

const LoadingHandler = () => {

  return (
    <div className="loading-wrapper">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default LoadingHandler
