import React, { Component, useCallback, useEffect, useState } from 'react'
import './UserSignup.sass'
import { BASE } from './../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../Biometric/StatesComponents/LoadingHandler'

interface Props {
  onResponse: (response: any) => void
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

function UserDocumentCheckFail({ onResponse }: Props) {

    return (
      <>
        <div className="card">
          <h1>
            Ooops! <span>CPF incorreto</span>
          </h1>
          <p>
          O CPF informado está incorreto ou não é compatível com o cadastro inicial.<br/><br/>
          Por favor, confira com o sócio torcedor titular que lhe forneceu este acesso, se o seu CPF foi corretamente cadastrado e se este link condiz com o seu registro.
          </p>
          <div className="form-group form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Tentar Novamente"
              onClick={() => onResponse({
                step: 'documentCheck'
              })}
            />
          </div>
          {/* <a className="help-link" href="#">
          Ajuda
        </a> */}
        </div>
        <ul className="step-counter">
          <li className="active"></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

export default UserDocumentCheckFail
